import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Image from 'gatsby-image'

import * as headerCSS from './css/header.module.css'

const logoQuery = graphql`
  query {
    content: homepageJson {
      sections {
        header {
          logo {
            alt
          }
        }
      }
    }
    image: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "yahoo_logo.png"}) {
      sharp: childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default function Header({style = {}, logoImage}) {
  const {image, content} = useStaticQuery(logoQuery)

  return (
    <header className={headerCSS.header} style={style}>
      <div className="container">
        <Image
          fluid={logoImage || image.sharp.fluid}
          style={{display: 'block'}}
          fadeIn={false}
          className={headerCSS.logo}
          alt={content.sections.header.logo.alt}
        />
      </div>
    </header>
  )
}
